import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"
import Imagerunning from "../images/running-page.jpeg";

function RunningAnalysis() {
    return (
        <div>
            <Helmet>
                <title>Running Analysis & Injury Prevention | Kinetic Impact</title>
                <meta property="og:title" content="Running Analysis & Injury Prevention | Kinetic Impact" />
                <meta property="og:image" content={OpenGraphLogo} />
                <meta property="og:description" content="Expert running analysis, injury prevention, and rehabilitation services. Specialized in return-to-running strategies and performance enhancement through strength training." />
                <meta name="description" content="Transform your running with professional analysis and injury prevention strategies. Our expert team helps runners stay healthy, build strength, and optimize performance." />
                <meta name="keywords" content="running analysis, injury prevention, return to running, strength training for runners, running rehabilitation, performance enhancement" />
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} 
                  header={"Expert Running Analysis & Injury Prevention"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Ready to run stronger and injury-free? Our specialized team combines chiropractic and physiotherapy expertise to help runners of all levels prevent injuries, manage existing conditions, and enhance performance through proper strength training.
                    </p>
                    
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Whether you're dealing with an injury, looking to prevent future problems, or aiming to improve your running performance, our evidence-based approach provides the support and guidance you need to achieve your goals.
                    </p>

                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Let's work together to keep you running strong and healthy.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Book Your Assessment
                        </button>
                    </a>

                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={Imagerunning} alt="Movement screening analysis"/>
                    </div>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Our Running Services</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Prevention & Management</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our comprehensive approach helps you identify and address potential injury risks before they become problems. For active injuries, we provide expert assessment and treatment to get you back to running safely and effectively.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Return to Running Programs</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Coming back from an injury requires careful planning and progression. We develop personalized return-to-running strategies that balance your recovery needs with your running goals, ensuring a safe and confident return to activity.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Running-Specific Strength Training</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn how to effectively incorporate strength training into your running routine. We'll help you develop the power and stability needed for better performance while reducing injury risk through targeted exercises and proper progression.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Performance Enhancement</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Take your running to the next level with our performance-focused approach. We help you develop key qualities like strength, power, and speed through specialized training techniques tailored to your goals.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Start Your Running Journey
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Training Components</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Analysis</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Understanding your running mechanics is crucial for preventing injuries and improving performance. Our detailed analysis helps identify areas for improvement and guides our treatment approach.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Strength Development</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Build running-specific strength through targeted exercises and training programs. We focus on developing key muscle groups and movement patterns that enhance your running efficiency and protect against injury.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Speed & Power Training</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your running performance through proper development of speed and power. Our approach includes specific exercises and drills designed to enhance these crucial running qualities safely and effectively.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Recovery Optimization</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn effective recovery strategies to support your training and prevent overuse injuries. We'll help you develop proper self-care techniques and manage your training load for optimal performance and longevity.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Book Your Assessment Today
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default RunningAnalysis